import React from 'react'

import SEO from '@components/seo'
import Text from '@components/Text'
import PageTransition from '@components/PageTransition'

const NotFoundPage = ({ location }) => (
  <PageTransition location={location} pagePath="/">
    <div style={{ backgroundColor: 'white', minHeight: '100vh', paddingTop: '12rem', paddingLeft: '2rem' }}>
      <SEO title="404: Not found" />
      <Text tag="h1" type="h1">
        NOT FOUND
      </Text>
      <Text tag="div" type="p" style={{ fontFamily: "inherit" }}>You just hit a route that doesn&#39;t exist.</Text>
    </div>
  </PageTransition>
)

export default NotFoundPage
